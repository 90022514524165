import "./src/styles/reset.scss";
import "./src/styles/index.scss";
import "./src/styles/variables.scss";

export function onRouteUpdate() {
  if (typeof document !== 'undefined') {
    document.body.scrollTo(0, 0);
  } else {
    window.scrollTo(0, 0);
  }
};
